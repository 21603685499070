import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import { object } from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { Footer } from '../components/footer';
import Navbar from '../components/navbar';
import loadingLottie from '../assets/loading.json';

import '../styles/global.css';
import SEO from '../SEO';
import { pageMeta } from '../utils/common';
import CardPengaduan from '../components/cardPengaduan';
import Wrapper from '../components/Wrapper';
import GTM_TRACKERS from '../../constants/gtm-trackers';

export default function PeluangDeposito(props) {
  const [listDeposits, setListDeposits] = useState([]);
  const [loading, setLoading] = useState(false);

  const findDeposito = (options) => {
    setListDeposits([]);
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        pagination: {
          limit: 10,
          offset: 0,
        },
        filter: {
          tenor: '',

        },
        status: null,
        ...options,
      }),
    };
    fetch('/api-v2/deposito', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setListDeposits(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    findDeposito();
  }, [setListDeposits]);

  if (listDeposits === null) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Lottie animationData={loading} className=" w-1/6 h-auto" />
      </div>
    );
  }

  return (
    <Wrapper>
      <SEO
        title={pageMeta.peluangTitle}
        metaDescription={pageMeta.peluangDesc}
        keyword="Peluang Deposito, depositobpr, bpr, bank perkreditan rakyat"
      />
      <Navbar />
      <div className="container px-0 mx-auto items-center 2xl:max-w-screen-xl">
        <div className="grid mx-auto px-4 lg:px-0 lg:flex columns-3 lg:justify-between py-4">
          <p className="text-2xl text-black self-center font-bold">Peluangku</p>
          <div className="lg:flex">
            <div>
              <div className="form-group items-center">
                <label className="text-sm">
                  Tenor
                </label>
                <div className="">
                  <select className="form-control rounded-lg" onChange={(e) => findDeposito({ filter: { tenor: e.target.value } })}>
                    <option value="">Semua</option>
                    <option value="1">1 Bulan</option>
                    <option value="2">2 Bulan</option>
                    <option value="3">3 Bulan</option>
                    <option value="4">4 Bulan</option>
                    <option value="5">5 Bulan</option>
                    <option value="6">6 Bulan</option>
                    <option value="7">7 Bulan</option>
                    <option value="8">8 Bulan</option>
                    <option value="9">9 Bulan</option>
                    <option value="10">10 Bulan</option>
                    <option value="11">11 Bulan</option>
                    <option value="12">12 Bulan</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="px-0 py-4 lg:py-0 lg:mx-8">
              <div className="form-group items-center">
                <label className="text-sm">Sort by</label>
                <div className="">
                  <select
                    className="form-control rounded-lg"
                    onChange={(e) => {
                      const values = e.target.value.split('.');
                      const options = {
                        sort: {
                          field: values[0],
                          order: values[1],
                        },
                      };
                      findDeposito(options);
                    }}
                  >
                    <option value="">Urutkan</option>
                    <option value="nama_bpr.ASC">Nama Produk - A ke Z</option>
                    <option value="nama_bpr.DESC">Nama Produk - Z ke A</option>
                    <option value="suku_bunga.DESC">Suku Bunga - Tertinggi</option>
                    <option value="suku_bunga.ASC">Suku Bunga - Terendah</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div className="form-group items-center">
                <label className="text-sm">Kategori</label>
                <div className="">
                  <select
                    className="form-control rounded-lg"
                    onChange={(e) => {
                      const values = e.target.value.split('.');
                      findDeposito({ filter: { [values[0]]: values[1] } });
                    }}
                  >
                    <option>Pilih Kategori</option>
                    <option value="is_cbs.1">E-Deposito</option>
                    <option value="is_cbs.0">Deposito</option>
                    <option value="is_bprs.1">Syariah</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <Lottie animationData={loadingLottie} className=" w-1/6 h-auto" />
          </div>
        ) : null}
        <div className="">
          <ul>
            {
              listDeposits && Array.isArray(listDeposits.data) && listDeposits.data.map((listDeposit) => (
                <div className="mx-auto pt-5">
                  <div className="card mx-4 lg:mx-0">
                    <div className="card-blue bg-blue-light40 self-center grid grid-cols-1 py-5 sm:py-0 xl:grid-cols-3 px-5">
                      <div className="text-center sm:text-left lg:pt-3">
                        <li key={listDeposit.id}>
                          <span className="text-sm text-white">{listDeposit.tenor} Bulan</span>
                          <br />
                          <div className="text-fourth">
                            <span className="font-bold text-md lg:text-2xl">
                              {listDeposit.suku_bunga}%
                            </span> p.a
                          </div>
                          <br />
                        </li>
                      </div>
                      <div className="text-center sm:text-left lg:pt-3">
                        <li key={listDeposit.id}>
                          <span className="text-sm text-white">Minimum Deposito</span>
                          <br />
                          <span className="font-bold text-md text-white lg:text-2xl">Rp. {Number(listDeposit.nominal).toLocaleString()}</span>
                        </li>
                      </div>
                      <div className="py-2 text-center sm:text-left lg:pt-8">
                        <li key={listDeposit.id}>
                          <div className={`text-center grid content-center mx-auto ${GTM_TRACKERS.PELUANG.AJUKAN_DEPOSITO}`}>
                            <a
                              className="mx-auto rounded-lg bg-green-light60 font-bold text-white py-2 px-4 text-center detail-btn"
                              href={`https://user.depositobpr.id/signup/deposan${props.location.search}`}
                            >
                              Ajukan Deposito
                            </a>
                          </div>
                        </li>
                      </div>
                    </div>
                    <div className="grid xl:grid-cols-3 lg:px-5 lg:py-2">
                      <div className="py-2 text-center sm:text-left">
                        <li key={listDeposit.id}>
                          <span className="font-bold lg:text-base">{listDeposit.nama || listDeposit.nama_bpr}</span>
                          <br />
                          <span className="flex justify-center lg:justify-start lg:text-sm">
                            <StaticImage
                              src="../images/location.svg"
                              className="mr-2"
                              objectFit="contain"
                            />
                            {listDeposit.kota}, {listDeposit.provinsi}
                          </span>
                        </li>
                      </div>
                      <div className="py-2 text-center sm:text-left">
                        <div className="flex justify-center lg:justify-start">
                          <span
                            className={`bg-blue-light40 items-center text-white text-sm justify-center text-justify flex px-2 py-1 rounded-md my-2
                            mr-2 with-tooltip ${(listDeposit.ro_enabled ? '' : 'hidden')}`}
                            data-tooltip-content="Automatic Roll Over (ARO) atau Perpanjang Otomotis adalah fitur untuk memperpanjang langsung deposito
                                Anda tanpa harus pengajuan lagi dengan nominal dan tenor yang sama. Tetapi, bunga akan dikirimkan setelah jatuh tempo."
                          >
                            ARO
                          </span>
                          <span
                            className={`bg-blue-light40 items-center text-white text-sm text-justify flex px-2 py-1 rounded-md my-2 with-tooltip 
                                ${(listDeposit.is_cbs ? '' : 'hidden')}`}
                            data-tooltip-content="E-deposito merupakan fitur terbaru yang dapat memudahkan Anda dalam proses penempatan deposito
                                di BPR secara online, tanpa harus menerima bilyet fisik. Deposan akan menerima Advis elektronik, yang merupakan bukti
                                penempatan deposito."
                          >
                            <StaticImage
                              src="../images/e-deposito.svg"
                              alt="edeposito-logo"
                              className="edeposito-logo"
                              placeholder="none"
                            />
                            <span className="mx-1 text-sm">E-Deposito</span>
                            <StaticImage
                              src="../images/coolicon.svg"
                              alt="coolicon-logo"
                              className="coolicon-logo"
                              placeholder="none"
                            />
                          </span>
                          <span
                            className={`bg-green-light60 items-center text-white text-sm text-justify flex px-2 py-1 rounded-md my-2 with-tooltip 
                                ${(listDeposit.is_bprs ? '' : 'hidden')}`}
                            data-tooltip-content="BPRS adalah Bank Pembiayaan Rakyat Syariah yang melaksanakan kegiatan usaha berdasarkan prinsip syariah,
                                yang dalam kegiatannya tidak memberikan jasa dalam lalu-lintas pembayaran."
                          >
                            <StaticImage
                              src="../images/syariah-logo.png"
                              alt="syariah-logo"
                              className="syariah-logo"
                              placeholder="none"
                            />
                            <span className="mx-1 text-sm">Syariah</span>
                            <StaticImage
                              src="../images/coolicon.svg"
                              alt="coolicon-logo"
                              className="coolicon-logo"
                              placeholder="none"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </ul>
        </div>
      </div>
      <CardPengaduan />
      <Footer />
    </Wrapper>
  );
}

PeluangDeposito.propTypes = {
  location: object.isRequired,
};
